<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='sda m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.account.ee5fa805') }}</div>
        </div>
        <skeleton-loader-component class='mt-24'
          :loading='!profile'>
          <profile-section/>
          <providers-section/>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import store from '@/store'
  import Profile from './profile'
  import Providers from './providers'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation,
      'profile-section': Profile,
      'providers-section': Providers
    },

    computed: {
      profile () {
        return this.$store.state.me.profile
      }
    },

    mounted () {
      this.$store.dispatch('me/fetchProfile')
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .sda {
    max-width: 480px;
  }
</style>
