<template>
  <div>
    <div class='mt-48'>
      <button-element class='is-block p-16 text-gray-700 bg-white shadow-sm'
        :class='{ "bg-gray-300": linkedProviders["facebook.com"] }'
        @click.native='linkOrUnlink("facebook.com")'>
        <spinner-element class='mr-16 -ml-16 text-gray-500'
          v-if='loadings["facebook.com"]'/>
        <facebook-svg class='mr-16 h-24'/>
        <div v-if='!linkedProviders["facebook.com"]'>{{ $t('pages.account.4e448c8a') }}</div>
        <div v-else>{{ $t('pages.account.a49bb6e3') }}</div>
      </button-element>
      <button-element class='is-block p-16 mt-16 text-gray-700 bg-white shadow-sm'
        :class='{ "bg-gray-300": linkedProviders["google.com"] }'
        @click.native='linkOrUnlink("google.com")'>
        <spinner-element class='mr-16 -ml-16 text-gray-500'
          v-if='loadings["google.com"]'/>
        <google-svg class='mr-16 h-24'/>
        <div v-if='!linkedProviders["google.com"]'>{{ $t('pages.account.d254ce0a') }}</div>
        <div v-else>{{ $t('pages.account.9f0767e0') }}</div>
      </button-element>
      <button-element class='is-block p-16 mt-16 text-gray-700 bg-white shadow-sm'
        :class='{ "bg-gray-300": linkedProviders["apple.com"] }'
        @click.native='linkOrUnlink("apple.com")'>
        <spinner-element class='mr-16 -ml-16 text-gray-500'
          v-if='loadings["apple.com"]'/>
        <apple-svg class='mr-16 h-24'/>
        <div v-if='!linkedProviders["apple.com"]'>{{ $t('pages.account.6bf6bba4') }}</div>
        <div v-else>{{ $t('pages.account.2bebd24a') }}</div>
      </button-element>
    </div>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "credential-already-in-use"'>{{ $t('pages.account.b2965cd9') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.account.7bc4505f') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  import Apple from '@/assets/graphics/apple.svg'
  import Facebook from '@/assets/graphics/facebook.svg'
  import Google from '@/assets/graphics/google.svg'

  export default {
    components: {
      'apple-svg': Apple,
      'facebook-svg': Facebook,
      'google-svg': Google
    },

    data: () => ({
      linkedProviders: {
        'apple.com': false,
        'facebook.com': false,
        'google.com': false
      },
      error: '',
      loadings: {
        'apple.com': false,
        'facebook.com': false,
        'google.com': false
      }
    }),

    computed: {
      user () {
        return this.$store.state.auth.user
      }
    },

    mounted () {
      this.handleRedirectResult()
      this.subscribeProviderData()
    },

    destroyed () {
      this.unsubscribeProviderData()
    },

    methods: {
      subscribeProviderData () {
        this.intervalId = setInterval(() => {
          let providerIds = this.user.providerData
            .map(data => data.providerId)
          Object.keys(this.linkedProviders).forEach(id => {
            this.linkedProviders[id] = providerIds.includes(id)
          })
        }, 500)
      },

      unsubscribeProviderData () {
        clearInterval(this.intervalId)
      },

      linkOrUnlink (providerId) {
        this.linkedProviders[providerId]
          ? this.unlink(providerId)
          : this.linkWithRedirect(providerId)
      },

      async linkWithRedirect (providerId) {
        try {
          this.loadings[providerId] = true

          await this.$store.dispatch('auth/linkWithRedirect', { providerId })
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loadings[providerId] = false
        }
      },

      async unlink (providerId) {
        try {
          this.loadings[providerId] = true

          await this.$store.dispatch('auth/unlink', { providerId })
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loadings[providerId] = false
        }
      },

      async handleRedirectResult () {
        try {
          await this.$store.dispatch('auth/getRedirectResult')
        } catch (error) {
          if (
            error.code === 'auth/credential-already-in-use' ||
            error.code === 'auth/email-already-in-use'
          ) {
            this.error = 'credential-already-in-use'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        }
      }
    }
  }
</script>
