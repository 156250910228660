<template>
  <div>
    <collapse-element class='mt-24'
      v-if='hasPasswordProvider'>
      <template #header>
        <input-element class='shadow-sm'
          type='password'
          v-model='inputs.password'
          :label='$t("common.password")'
          :placeholder='$t("common.current-password")'/>
      </template>
      <input-element class='mt-16 shadow-sm'
        type='password'
        v-model='inputs.newPassword'
        :placeholder='$t("common.new-password")'/>
      <input-element class='mt-16 shadow-sm'
        type='password'
        v-model='inputs.retypeNewPassword'
        :placeholder='$t("common.retype-new-password")'/>
      <div class='mt-24 text-right'>
        <button-element class='is-outlined'
          :disabled='loading'
          @click.native='updatePassword'>
          <spinner-element class='mr-16 -ml-16 text-gray-500'
            v-if='loading'/>
          <div>{{ $t('common.update') }}</div>
        </button-element>
      </div>
    </collapse-element>
    <snackbar-element
      ref='successSnackbar'
      :duration='3000'>
      <div class='flex p-16 text-green'>
        <i class='icon mr-16'>check_circle</i>
        <div v-if='success === "password-updated"'>{{ $t('pages.account.fe0c7f9d') }}</div>
      </div>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "password-not-match"'>{{ $t('pages.account.32b26b86') }}</div>
        <div v-if='error === "wrong-password"'>{{ $t('pages.account.3372b0c6') }}</div>
        <div v-if='error === "weak-password"'>{{ $t('pages.account.99d63418') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.account.7bc4505f') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  export default {
    data: () => ({
      inputs: {
        password: '',
        newPassword: '',
        retypeNewPassword: ''
      },
      success: '',
      error: '',
      loading: false
    }),

    computed: {
      user () {
        return this.$store.state.auth.user
      },

      hasPasswordProvider () {
        let providerIds = this.user.providerData.map(data => data.providerId)
        return providerIds.includes('password')
      }
    },

    methods: {
      clearInputs () {
        this.inputs = {
          password: '',
          newPassword: '',
          retypeNewPassword: ''
        }
      },

      async updatePassword () {
        let { password, newPassword, retypeNewPassword } = this.inputs

        if (newPassword !== retypeNewPassword) {
          this.error = 'password-not-match'
          this.$refs.errorSnackbar.show()
          return
        }

        try {
          this.loading = true

          await this.$store.dispatch('auth/reauthenticate', { password })
          await this.$store.dispatch('auth/updatePassword', { newPassword })

          this.success = 'password-updated'
          this.$refs.successSnackbar.show()
          this.clearInputs()
        } catch (error) {
          if (error.code === 'auth/wrong-password') {
            this.error = 'wrong-password'
            this.$refs.errorSnackbar.show()
            return
          }

          if (error.code === 'auth/weak-password') {
            this.error = 'weak-password'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
