<template>
  <div>
    <email-section/>
    <div>
      <input-element class='shadow-sm'
        disabled
        type='text'
        :label='$t("common.email")'
        :value='profile.email'/>
      <input-element class='mt-24 shadow-sm'
        type='text'
        v-model='inputs.name'
        :label='$t("common.name")'/>
      <password-section/>
      <checkbox-element class='mt-24'
        v-model='inputs.allowNewsletter'>
        {{ $t('pages.account.8b6fe252') }}
      </checkbox-element>
    </div>
    <button-element class='is-block p-16 mt-24'
      :disabled='loading'
      @click.native='updateProfile'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'
        v-if='loading'/>
      <div>{{ $t('common.update') }}</div>
    </button-element>
    <snackbar-element
      ref='successSnackbar'
      :duration='3000'>
      <div class='flex p-16 text-green'>
        <i class='icon mr-16'>check_circle</i>
        <div v-if='success === "profile-updated"'>{{ $t('pages.account.04444b41') }}</div>
      </div>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "unknown"'>{{ $t('pages.account.7bc4505f') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  import Email from './email'
  import Password from './password'

  export default {
    components: {
      'email-section': Email,
      'password-section': Password
    },

    data: () => ({
      inputs: {
        name: '',
        allowNewsletter: false
      },
      success: '',
      error: '',
      loading: false
    }),

    computed: {
      profile () {
        return this.$store.state.me.profile
      }
    },

    watch: {
      profile: {
        handler () {
          this.updateInputs()
        },
        immediate: true
      }
    },

    methods: {
      updateInputs () {
        if (!this.profile) return

        let { name, allowNewsletter } = this.profile
        this.inputs.name = name
        this.inputs.allowNewsletter = allowNewsletter
      },

      async updateProfile () {
        let profile = this.inputs

        if (!profile.name) return

        try {
          this.loading = true

          await this.$store
            .dispatch('me/updateProfile', { profile })

          this.success = 'profile-updated'
          this.$refs.successSnackbar.show()
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
