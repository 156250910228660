<template>
  <div>
    <div class='mb-24'
      v-if='!profile.isEmailVerified'>
      <div class='flex text-orange'>
        <i class='icon'>warning</i>
        <div class='ml-16'>{{ $t('pages.account.30a8d264') }}</div>
      </div>
      <div class='mt-24 text-right'>
        <button-element class='is-outlined'
          :disabled='loading'
          @click.native='sendOrRefresh'>
          <spinner-element class='mr-16 -ml-16 text-gray-500'
            v-if='loading'/>
          <div v-if='!emailSent'>{{ $t('common.send-email') }}</div>
          <div v-else>{{ $t('common.refresh-page') }}</div>
        </button-element>
      </div>
    </div>
    <snackbar-element
      ref='successSnackbar'
      :duration='3000'>
      <div class='flex p-16 text-green'>
        <i class='icon mr-16'>check_circle</i>
        <div v-if='success === "email-sent"'>{{ $t('pages.account.3a03cd70') }}</div>
      </div>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "too-many-requests"'>{{ $t('pages.account.8c0389ee') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.account.7bc4505f') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  export default {
    data: () => ({
      emailSent: false,
      success: '',
      error: '',
      loading: false
    }),

    computed: {
      profile () {
        return this.$store.state.me.profile
      }
    },

    methods: {
      sendOrRefresh () {
        this.emailSent
          ? this.refreshToken()
          : this.sendEmail()
      },

      async sendEmail () {
        try {
          this.loading = true

          await this.$store.dispatch('auth/sendEmailVerification')

          this.emailSent = true
          this.success = 'email-sent'
          this.$refs.successSnackbar.show()
        } catch (error) {
          if (error.code === 'auth/too-many-requests') {
            this.error = 'too-many-requests'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      },

      async refreshToken () {
        await this.$store.dispatch('auth/refreshIdToken')
        location.reload()
      }
    }
  }
</script>
